/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import tableIcons from '../constants/TableIcons';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import Box from '@material-ui/core/Box';
import { useLocation, useHistory } from "react-router-dom";

import { setConfirmationModalOpen, setSnackBarMessage } from '../actions/interactions';
import { fetchDataTable } from '../utils/Helpers';

const mapStateToProps = state => {
  return {
    show: state.portal.isConfirmationModalOpen.show,
    org: state.portal.selectedOrg
  };
};

function mapDispatchToProps(dispatch) {
  return {
    openConfirmationModal: (item) => dispatch(setConfirmationModalOpen(item)),
    setSnackBarMessage: (message, type) => dispatch(setSnackBarMessage(message, type)),
  };
}

const ConnectedTableComponent =  (props) => {
  const location = useLocation()
  const history = useHistory()

  let MANUAL_PAGE = false
  let NEW_PAGE = false
  const [isInternalQueryChange, setQueryChange] = useState(false)
  let page = (new URLSearchParams(window.location.search)).get("page")

  useEffect(() => {
    if (page && !isInternalQueryChange){
      NEW_PAGE = true
      refreshTable()
    }
    if (isInternalQueryChange){
      setQueryChange(false)
    }
  }, [page])

  const refreshTable = ()=> {
    props.tableRef.current && props.tableRef.current.onQueryChange()
  }

  const errHandler = ()=> {
    props.setSnackBarMessage(props.errMessage, 'error')
  }

  const handleGetTableData = (query) => {
    if (NEW_PAGE && page){
      let newPage = parseInt(page)
      query.page = newPage - 1
      NEW_PAGE = false
      MANUAL_PAGE= true
      return fetchDataTable(
        query, props.getTableData, props.org.handle, refreshTable, errHandler
      )
    }
    else if (query.page === 0 && (page && MANUAL_PAGE)){
      //this runs if a user manually enters a page number
      let newPage = parseInt(page)
      query.page = newPage - 1
      MANUAL_PAGE = false
      return fetchDataTable(
        query, props.getTableData, props.org.handle, refreshTable, errHandler
      )
    }else{
      history.push(`${location.pathname}?page=${query.page + 1}`)
      setQueryChange(true)
      return fetchDataTable(
        query, props.getTableData, props.org.handle, refreshTable, errHandler
      )
    }
  }

  const handleDeleteItem = (rowData) => {
    return props.handleDeleteItem(rowData)
      .then(() => refreshTable() );
  }

  const addBoxIcon = (props) => (
    <AddBoxIcon {...props} color='secondary'/>
  );

  const refreshIcon = (props) => (
    <RefreshIcon {...props} />
  );

  const installItemIcon = (props) => (
    <AddIcon {...props} color='action'/>
  );

  const removeItemIcon = (props) => (
    <DeleteIcon {...props} color='action'/>
  );

  let actions=[
    {
      icon: addBoxIcon,
      tooltip: props.newItemTooltip,
      onClick: props.handleNewItem,
      isFreeAction: true
    },
    {
      icon: refreshIcon,
      tooltip: 'Refresh Data',
      onClick: refreshTable,
      isFreeAction: true,
    },
    {
      icon: installItemIcon,
      tooltip: props.installTooltip,
      onClick: (event, rowData) => {
        props.setItemToInstall(rowData)
      }
    },
    {
      icon: removeItemIcon,
      tooltip: props.removeItemTooltip,
      onClick: (event, rowData) => {
        props.openConfirmationModal(
          {
            show: true,
            message: props.delConfirmationMessage,
            onYes: () => {handleDeleteItem(rowData)
              .then(props.openConfirmationModal({show: false, message: null, onYes: function() {}}))
            }
          }
        );
      }
    },
  ]

  const propActions = props.actions || [];
  const TableActions = [
    ...actions.filter((obj) => typeof obj.tooltip != "undefined"),
    ...propActions
  ];

  return (
    <div>
      <MaterialTable 
        tableRef={props.tableRef}
        title={props.title}
        columns={props.columns}
        data={handleGetTableData}
        icons={tableIcons}
        actions={TableActions}
        editable={props.editable}
        options={{
            headerStyle: {
              backgroundColor: '#FFFFFF',
            }
        }}
        components = {{
            Container: p => (
              <Box {...p} />
            )
        }}
      >
      </MaterialTable>  
    </div>
  );
}

const TableComponent = connect(mapStateToProps, mapDispatchToProps)(ConnectedTableComponent);

export default TableComponent;
