/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import DonutSmallIcon from '@material-ui/icons/DonutSmall';
import TollIcon from '@material-ui/icons/Toll';

import CopyToClipBoard from '../CopyToClipBoard';

import { setSnackBarMessage } from '../../actions/interactions';
import { getAddDevicesToken } from '../../utils/Cachengo';
import { userManager } from '../../utils/userManager';
import { ROOT_URL } from '../../utils/Constants';

const mapStateToProps = state => {
  return {
    currentUser: state.oidc.user,
  }
};

const ConnectedAddDevicesModal = props => {
  const [addDeviceToken, setAddDeviceToken] = useState("");
  const [isTokenReady, setTokenReady] = useState(false);

  const dispatch = useDispatch()

  useEffect(() => {
    if (props.show) {
      userManager.signinSilent({"max_age": 3600})
        .then((user) => {
          getAddDevicesToken(user.access_token, props.manufacturerOrg)
            .then(res => {
              setAddDeviceToken(res.token);
              setTokenReady(true);
            }).catch(err => {
              dispatch(setSnackBarMessage('Error generating token', 'error'));
            })
        }).catch(err => {
          dispatch(setSnackBarMessage('Error refreshing access token', 'error'));
        })
    }
    return () => {
      setTokenReady(false);
    };
  }, [dispatch, props.show, props.manufacturerOrg]);

  const cmdline = `cachengo-cli deviceInit --host ${ROOT_URL} --org ${props.manufacturerOrg.handle} --token ${addDeviceToken}`

  return (
    <Dialog
      open={props.show}
      onClose={props.handleClose}
      fullWidth
    >
      <DialogTitle>
        Add Devices
      </DialogTitle>
      <DialogContent>
        <Typography align="left" variant="subtitle2" style={{paddingLeft: '0px'}}>
          Use the following command line to add devices to this portal
        </Typography>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor: '#FC5001' }}>
                <DonutSmallIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <TextField
                label={isTokenReady ? "Command line" : "Generating token..."}
                value={isTokenReady ? cmdline : ""}
                margin='dense'
                type='text'
                variant='outlined'
                fullWidth
                color='secondary'
                disabled
              />
            </ListItemText>
            <ListItemSecondaryAction>
              {isTokenReady && <CopyToClipBoard item={cmdline} />}
            </ListItemSecondaryAction>
          </ListItem>
          If you just need the token to incorporate in your own environment:
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{backgroundColor: '#FC5001' }}>
                <TollIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <TextField
                label='Token'
                value={isTokenReady ? addDeviceToken : ""}
                margin='dense'
                type='text'
                variant='outlined'
                fullWidth
                color='secondary'
                disabled
              />
            </ListItemText>
            <ListItemSecondaryAction>
              {isTokenReady && <CopyToClipBoard item={addDeviceToken} />}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

const AddDevicesModal = connect(mapStateToProps)(ConnectedAddDevicesModal);

export default AddDevicesModal;
