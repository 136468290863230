/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';

import OrganizationList from '../OrganizationList';
import { editDeviceInfo } from '../../utils/Cachengo'
import { connect } from "react-redux";
import { runOnEnter } from '../../utils/Helpers';

const mapStateToProps = state => {
  return {
    org: state.portal.selectedOrg
  }
}


class ConnectedDeviceEditModal extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.saveValues = this.saveValues.bind(this);
    this.handleListItem = this.handleListItem.bind(this);
    this.changeOrg = this.changeOrg.bind(this);
    this.state = {};
  }
  
  changeOrg(organization) {
    this.setState({organization: organization});
  }

  onChange = field => event => {
    var state = {}
    state[field] = event.target.value
    this.setState(state)
  }

  handleListItem = (event) => {
    this.setState({organization: event})
  }

  saveValues() {
    var payload = Object.assign({handle: this.props.handle, organization: this.props.org.handle}, this.state);
    editDeviceInfo(payload)
      .then(res => this.props.refreshTable())
    this.props.handleClose();
  }

  render() {
    return (
      <Dialog
        open={this.props.show}
        onClose={this.props.handleClose}
        aria-labelledby={this.props.name}
        fullWidth
      >
        <DialogTitle id={this.props.name}>
          <Grid justify="space-between" container spacing={2}>
            <Grid item>
              <Typography variant="h6" align="center">Edit Device Information</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            defaultValue={this.props.name}
            onChange={this.onChange('name')}
            margin="dense"
            type="text"
            fullWidth
            color='secondary'
            onKeyPress={runOnEnter(this.saveValues)}
          />
          <TextField
            disabled
            label="Handle"
            defaultValue={this.props.handle}
            onChange={this.onChange('handle')}
            margin="dense"
            type="text"
            fullWidth
            color='secondary'
          />
          <OrganizationList 
            onChange={this.handleListItem}
            value={this.state.organization || this.props.org.handle}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.saveValues}>Save</Button>
          <Button onClick={this.props.handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const DeviceEditModal = connect(mapStateToProps, null)(ConnectedDeviceEditModal);

export default DeviceEditModal;
