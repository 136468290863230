/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 
 http://www.apache.org/licenses/LICENSE-2.0
 
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React, { useState } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements } from "@stripe/react-stripe-js";

import { setConfirmationModalOpen } from "../actions/interactions";
import { CACHENGO_PAYMENTS_URL, STRIPE_PROMISE_KEY } from "../utils/Constants";
import CustomerRegister from "./CustomerRegister";

const stripePromise = loadStripe(STRIPE_PROMISE_KEY);

const mapStateToProps = (state) => {
  return {
    payInfo: state.portal.payInfo || {},
  };
};

function mapDispatchToProps(dispatch) {
  return {
    openConfirmationModal: (item) => dispatch(setConfirmationModalOpen(item)),
  };
}

function InfoCard({
  title,
  content,
  targetURL,
  targetText,
  subscriptionAction,
  history
}) {
  const goToMySales = () => {
    history.push("/my_sales");
  };
  return (
    <Box>
      <CardContent>
        <Typography variant="h5" component="h2" paragraph>
          {title}
        </Typography>
        {content}
      </CardContent>
      {targetURL ? (
        <CardActions>
          <Button size="small" color="secondary" href={targetURL}>
            {targetText}
          </Button>
          <Button size="small" color="secondary" onClick={goToMySales}>
            {subscriptionAction}
          </Button>
        </CardActions>
      ) : null}
    </Box>
  );
}

function SubInfoCard({ title, primaryText }) {
  return (
    <Box>
      <Typography variant="body2">{title}</Typography>
      <Typography variant="h5" component="h2">
        {primaryText}
      </Typography>
    </Box>
  );
}

function ConnectedPaymentsManager({ payInfo, history }) {
  const seller = payInfo.seller || {};
  const buyer = payInfo.buyer || {};
  const canSell = seller.can_sell || false;
  const canBuy = buyer.can_buy || false;

  const pending_balance = seller.pending_balance / 100;
  const available_balance = seller.available_balance / 100;
  
  const [changeCard, setChangeCard ] = useState(false);

  const notSellerContent = (
    <Typography color="textSecondary">
      Please go through our partner onboarding to become a Cachengo partner and
      start selling through our platform
    </Typography>
  );
  const sellerContent = (
    <Grid container spacing={2}>
      <Grid item md={6} lg={4}>
        <SubInfoCard title="Available" primaryText={`\$${available_balance}`} />
      </Grid>
      <Grid item md={6} lg={4}>
        <SubInfoCard title="Pending" primaryText={`\$${pending_balance}`} />
      </Grid>
    </Grid>
  );
  const notBuyerContent = <CustomerRegister changeCard ={changeCard} setChangeCard={setChangeCard} />;
  const buyerContent = (
    <Grid container spacing={2}>
      <Grid item >
        <SubInfoCard title="Balance" primaryText={`\$${buyer.balance}`} />
        <Button style={{marginTop:'16px'}} size="small" color="secondary" type="submit" onClick={()=> setChangeCard(!changeCard)}>
          Change Payment Method
      </Button>
      </Grid>
    </Grid>
  );

  const usage = (
    <InfoCard title="Usage" content={canBuy && !changeCard ? buyerContent : notBuyerContent} />
  );
  const sales = (
    <InfoCard
      title="Sales"
      content={canSell ? sellerContent : notSellerContent}
      targetText={canSell ? "Manage payouts" : "Get Started"}
      subscriptionAction={canSell ? "My Sales" : null}
      targetURL={`${CACHENGO_PAYMENTS_URL}${
        canSell ? "/view_dashboard" : "/stripe_connect"
      }`}
      history={history}
    />
  );
  return (
    <Grid container>
      <Grid style={{ paddingTop: "35px" }} container spacing={6}>
        <Elements stripe={stripePromise}>
          <Grid item md={6}>
            {usage}
          </Grid>
          <Grid item md={6}>
            {sales}
          </Grid>
        </Elements>
      </Grid>
    </Grid>
  );
}

const PaymentsManager = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedPaymentsManager);

export default PaymentsManager;
