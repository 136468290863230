/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 
 http://www.apache.org/licenses/LICENSE-2.0
 
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';

import { isAdmin } from '../utils/userManager';
import { removeUser, editUser, fetchOrgUsers} from '../utils/Cachengo';
import { setConfirmationModalOpen} from '../actions/interactions';
import { connect } from 'react-redux';
import AddUserModal from './modals/AddUserModal';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import OrgInfoCard from './OrgInfoCard';
import { useTheme } from '@material-ui/core/styles';
import { removeEventHandler, addEventHandler } from '../actions/items';
import TableComponent from './TableComponent';

const mapStateToProps = state => {
  return {
    org: state.portal.selectedOrg,
    currentUser: state.oidc.user
  };
};

function mapDispatchToProps(dispatch) {
  return {
    openConfirmationModal: (item) => dispatch(setConfirmationModalOpen(item)),
    removeEventHandler: (key) => dispatch(removeEventHandler(key)),
    addEventHandler: (key, handler) => dispatch(addEventHandler(key, handler))
  };
}

function ConnectedOrgManager(props) {
  const tableRef = React.useRef()
  const [isModalOpen, setIsModalOpen] = useState(false) 
          
  useEffect(() => {
    props.addEventHandler('orgManager', (message) => {
      if (message.event === 'add_user' || message.event === 'remove_user' || message.event === 'edit_user') {
        tableRef.current && tableRef.current.onQueryChange()
      }
    })
    return () => props.removeEventHandler('orgManager')
  }, [tableRef])

  const handleDeleteUser = rowData => {
    return removeUser(props.org.handle, rowData.name)
      .then(res => { refreshTable(); });
  }

  const toggleAddUserModal = event => {
    setIsModalOpen(!isModalOpen)
  } 

  const refreshTable = () => {
    tableRef.current && tableRef.current.onQueryChange()
  }

  const editable = rowData => {
    let permissions = {
      is_admin: rowData.is_admin,
      can_write: rowData.can_write,
      can_read: rowData.can_read,
      is_confirmed: rowData.is_confirmed
    }
    return editUser(props.org.handle, rowData.name, permissions);       
  }

  const columns = [
    {title: "Users", field: "name", editable: 'never'},
    {title: "Email", field: "email", editable: 'never'},
    {title: "Admin", field: 'is_admin', type: 'boolean'},
    {title: "Write", field: 'can_write', type: 'boolean'},
    {title: "Read", field: 'can_read', type: 'boolean'},
    {title: "Confirmed", field: 'is_confirmed', type: 'boolean'},
  ]

  const addUserIcon = (props) => (
    <PersonAddIcon {...props} color='secondary' />
  );
  
  const removeUserIcon = (props) => (
    <PersonAddDisabledIcon {...props} color='action' />
  );

  let actions = [
    {
      icon: removeUserIcon,
      tooltip: 'Remove user from org',
      onClick: (event, rowData) => {
        props.openConfirmationModal(
          {
            show: true,
            message: "Are you sure you want to remove this user from current organization?",
            onYes: () => {handleDeleteUser(rowData)
              .then(props.openConfirmationModal({show: false, message: null, onYes: function() {}}))
            }
          }
        )
      }
    },
    {
      icon: addUserIcon,
      tooltip: 'Add User to Org',
      isFreeAction: true,
      onClick: toggleAddUserModal,
    },
  ]

  const theme = useTheme();
  return (
      <div style={{paddingTop: '35px'}}>
        <OrgInfoCard />
        <Divider />
        <Toolbar />
        {isAdmin(props.currentUser, props.org.handle) &&
          <TableComponent
            tableRef={tableRef}
            columns={columns}
            actions={actions}
            getTableData={fetchOrgUsers}
            title={'Manage Users in Organization'}
            errMessage={'Problem fetching users'}
            editable={{
              onRowUpdate: (rowData) => {
                return editable(rowData);
              },                    
            }}
          />
        }
        <AddUserModal 
          org={props.org.handle}
          show={isModalOpen}
          handleClose={toggleAddUserModal}
          refreshTable={refreshTable}
        />
      </div>
  );
}

const OrgManager = connect(mapStateToProps, mapDispatchToProps)(ConnectedOrgManager);

export default OrgManager;
