/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 http://www.apache.org/licenses/LICENSE-2.0
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
import React, { useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import EditIcon from '@material-ui/icons/Edit';
import Button from "@material-ui/core/Button";

import ManufacturerAddDevices from './modals/ManufacturerAddDevices';
import ManufacturerDeviceEdit from './modals/ManufacturerDeviceEdit';
import ManufacturerSledEdit from './modals/ManufacturerSledEdit';
import { getManufacturerOrgs, get_devices_manufacturer, edit_device_manufacturer, edit_sled_manufacturer } from '../utils/Cachengo';
import { setConfirmationModalOpen, setSnackBarMessage } from '../actions/interactions';
import { setOrganization } from '../actions/organization';
import { connect } from 'react-redux';
import CustomTable from './CustomTable';


const mapStateToProps = state => {
  return {
    show: state.portal.isConfirmationModalOpen.show,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setOrg: (payload) => dispatch(setOrganization(payload)),
    setSnackBarMessage: (message, type) => dispatch(setSnackBarMessage(message, type)),
    openConfirmationModal: (item) => dispatch(setConfirmationModalOpen(item)),
  };
}

const ConnectedManufacturerTable = props => {
  const tableRef = useRef();
  const[isEditDeviceModalOpen, setDeviceModalOpen] = useState(false);
  const[isEditSledModalOpen, setSledModalOpen] = useState(false);
  const[isAddDevicesModalOpen, setAddDevicesModalOpen] = useState(false);
  const[currentDevice, setCurrentDevice] = useState({});
  const[manufacturerOrg, setManufacturerOrg] = useState("");

  useEffect(() => {
    getManufacturerOrgs()
      .then(res => {
        setManufacturerOrg(res.organizations[0]);
      }).catch(err => {
        props.setSnackBarMessage('Unable to determine manufacturer organization', 'error')
      });
  }, []);

  const refreshData = () => {
    tableRef.current && tableRef.current.refreshTable()
  }

  const handleClickEditDevice = (e, rowData) => {
    e.stopPropagation();
    setDeviceModalOpen(true)
    setCurrentDevice(rowData)
  };
  const handleClickEditSled = (e, rowData) => {
    e.stopPropagation();
    setSledModalOpen(true)
    setCurrentDevice(rowData)
  };

  const closeEditDeviceModal = () => {
    setDeviceModalOpen(false)
  }
  const closeEditSledModal = () => {
    setSledModalOpen(false)
  }

  const handleAddDevices = () => {
    setAddDevicesModalOpen(true)
  }

  const closeAddDevicesModal = () => {
    setAddDevicesModalOpen(false);
  }

  const ResponseErrHandler = (err) => {
    console.log(err)
    let msg = err.message ? err.message : 'Problem completing request'
    props.setSnackBarMessage(msg, 'error')
  }

  const handleSubmitDeviceEdit = (data) => {
    edit_device_manufacturer(data, ResponseErrHandler)
    .then(res=>{
      if(res && res.success){
        refreshData()
        closeEditDeviceModal()
      }
    })	
    
  }
  const handleSubmitSledEdit = (data) => {
    edit_sled_manufacturer(data, ResponseErrHandler)
    .then(res => {
      if (res && res.success) {
        refreshData()
        closeEditSledModal()
      }
    })
  }

  const deviceIcon = rowData => {
    let time = moment().diff(moment.unix(rowData.last_seen), 'minutes')
    let color = rowData.is_connected ? '#4caf50' : '#e53935';
    if (time > 6) { color = '#e53935'; }
    if (rowData.is_connected == null && time < 6) { color = '#4caf50'; }
    return (
      <FiberManualRecordIcon style={{ margin: '-3px 4px 0 0', color }} />
    )
  };
  const columnHead = {
    field: "name", renderIcon: deviceIcon
  }
  const columns = [
    {
      render: rowData => (
        <DeviceControls 
          rowData={rowData}
          handleClickEditDevice={handleClickEditDevice}
          handleClickEditSled={handleClickEditSled}
        />
      )
    },
    { title: "Device Position", field: "device_position" },
    { title: "Sled Position", field: "sled_number" },
    { title: "Sled handle", field: "sled_handle" },
    { title: "Product handle", field: "product_handle" },
    { title: "Org", field: "org" },
    { title: "Device Handle", field: "handle" },
  ]


  return (
    <Grid style={{ paddingTop: '20px' }}>
      <CustomTable
        ref={tableRef}
        title='All Devices'
        getData={get_devices_manufacturer}
        columns={columns}
        columnHead={columnHead}
        errMessage={'Problem fetching Devices'}
        searchOptions={['name', 'org_handle', 'sled_handle', 'product_handle']}
        createNewItemAction={{ tooltip: 'Add devices', handleCreateNew: handleAddDevices }}
      />
      <ManufacturerDeviceEdit
        show={isEditDeviceModalOpen}
        handleClose={closeEditDeviceModal}
        rowData={currentDevice}
        handleSubmit={handleSubmitDeviceEdit}
      />
      <ManufacturerSledEdit
        show={isEditSledModalOpen}
        handleClose={closeEditSledModal}
        rowData={currentDevice}
        handleSubmit={handleSubmitSledEdit}
      />
      <ManufacturerAddDevices
        show={isAddDevicesModalOpen}
        handleClose={closeAddDevicesModal}
        manufacturerOrg={manufacturerOrg}
      />
    </Grid>
  );
}

const ManufacturerTable = connect(mapStateToProps, mapDispatchToProps)(ConnectedManufacturerTable);

export default ManufacturerTable;

const DeviceControls = (props) => {
  return(
  <div style={{margin: '-12px 0 12px'}}>
    <Button
      size='small'
      color="secondary"
      startIcon={<EditIcon />}
      onClick={(e) => { props.handleClickEditDevice (e, props.rowData)}}
      style={{ marginRight: '12px' }}
    >
      Edit Device
    </Button>
    <Button
      size='small'
      color="secondary"
      startIcon={<EditIcon />}
      onClick={(e) => { props.handleClickEditSled(e, props.rowData) }}
    >
      Edit Sled
    </Button>
  </div>
  )
}
