/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
// src/js/constants/action-types.js

export const FULL_UPDATE = "FULL_UPDATE";
export const UPDATE_ORGS = "UPDATE_ORGS";
export const ADD_DEVICE_TO_INSTALL = "ADD_DEVICE_TO_INSTALL";
export const REMOVE_DEVICE_TO_INSTALL = "REMOVE_DEVICE_TO_INSTALL";
export const REMOVE_MULTIPLE_DEVICES_FROM_INSTALL = 'REMOVE_MULTIPLE_DEVICES_FROM_INSTALL'
export const REMOVE_EVENT_HANDLER = "REMOVE_EVENT_HANDLER";
export const ADD_EVENT_HANDLER = "ADD_EVENT_HANDLER";
export const GET_CATEGORIES = "GET_CATEGORIES";
