/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */


import React from 'react';
import { connect } from "react-redux";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import userManager from "../utils/userManager";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#404846',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const mapStateToProps = state => {
  return {
    isLoadingUser: state.oidc.isLoadingUser,
  }
}

const ConnectedLogin = props =>  {
  const classes = useStyles();

  const onLoginButtonClick = (e) => {
    e.preventDefault();
    userManager.signinRedirect();
  };

  if (props.isLoadingUser) {
    return (
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>Loading...</div>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5" align='center'>
          Welcome to Knowhere by Cachengo
        </Typography>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
          onClick={onLoginButtonClick}
        >
            Sign In
        </Button>
      </div>
    </Container>
  );
}

const Login = connect(mapStateToProps)(ConnectedLogin);

export default Login;
