/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import store from '../store';

import { CACHENGO_API_URL, CACHENGO_APPS_URL } from './Constants';

import {str2ab, UUIDToHex} from './Helpers';

export const headers = {	
	'Accept': 'application/json',
	'Content-Type': 'application/json'
}

export const checkResStatus = response => {
	// raises an error in case response status is not a success
	if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
		return response
	} else {
		var error = new Error(response.statusText)
		error.response = response
		throw error
	}
}

export const checkErrInRes = res => {
	if(!res.isError) {
		return res
	}else{
		throw res
	}
}

export async function fetchAuth(resource, options={}, token=null, org=null) {
    const state = store.getState();
    const user = state.oidc.user;

    if (!org) {
        org = state.portal.selectedOrg;
    }

    const addHeaders = {};

    if (org && org.handle) {
        addHeaders['x-cachengo-orgid'] = org.handle;
    }

    if (token) {
        addHeaders['Authorization'] = `Bearer ${token}`
    } else if (user && user.access_token) {
        addHeaders['Authorization'] = `Bearer ${user.access_token}`
    }

    options.headers = Object.assign(
        {},
        addHeaders,
        options.headers
    );

    return fetch(resource, options);
}

export async function postJSON(url, data, api=CACHENGO_API_URL){
	const body = JSON.stringify(data)

	const options = {
		headers: {...headers},
		method: 'POST',
		body: body,
		credentials: 'include',
	}

	return fetchAuth(`${api}/${url}`, 
		options
	).then(checkResStatus)
      .then(res => res.json())
		.catch(err => {
			//Add isError to the err and set it to true so to know if the response is an error or not
			err.isError = true
			if (!err.response) {
				console.log('error', err)
			}
			return err
		})
}  

export async function getResponse(url, api=CACHENGO_API_URL, token=null, org=null) {
	return fetchAuth(`${api}/${url}`,{
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		method: 'GET',
		credentials: 'include',
	}, token, org).then(checkResStatus)
		.catch(err => {
			//Add isError to the err and set it to true so to know if the response is an error or not
			err.isError = true
			if (!err.response) {
				console.log('error', err)
			}
			return err
		})
} 

export async function getJSON(url, api=CACHENGO_API_URL) {
	return getResponse(url, api).then(res => res.isError ? res : res.json())
}

export async function getJSONwOpts(url, {
  api = CACHENGO_API_URL,
  token = null,
  org = null,
}={}) {
	return getResponse(url, api, token, org).then(res => res.isError ? res : res.json())
}

export async function postJSONwErrHandler(url, data, errorHandler, api=CACHENGO_API_URL) {
	const body = JSON.stringify(data)
	const options = {
		headers: {...headers},
		method: 'POST',
		body: body,
		credentials: 'include',
	}

	return fetchAuth(`${api}/${url}`,
		options
	).then(checkResStatus)
      .then(res => res.json())
		.catch(err => {
			if (errorHandler !== undefined && err.response) {
				return err.response.json().then(res => errorHandler(res));
			}
			console.log('err', err);
		});
}


export function getAppLogo(app_handle) 
{	
	return fetchAuth(
		`${CACHENGO_APPS_URL}/loggedout/download_logo/${app_handle}`,
		{method: 'GET'}
	)
	.then(response =>
	{
		if(response.status!==200)
		{	
			const digest = UUIDToHex(app_handle);
			let url=`https://www.gravatar.com/avatar/${digest}?d=identicon`;
			return fetch(url,{method:'GET'});
		}
		else 
		{
			return response;
		}
	}).then(response =>{
		return response.blob();
	}).then (data =>{
		const objectUrl = URL.createObjectURL(data);
		return objectUrl;
	})
	.catch(ex =>{
		console.log("Failed to get logo URL.");
		return null;
	});
}

export async function uploadFileReq(url, data, errorHandler) {
    const formData  = new FormData();
    for(const name in data) {
        formData.append(name, data[name]);
    }
	const options = {
		method: 'POST',
		body: formData,
		credentials: 'include',
	}

	return fetch(`${url}`,
		options
	).then(checkResStatus)
}
