/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';


export default (props) => {
  const handleUpdateList = input => {
    let event = {
      target: {
        id: props.id,
        value: input.target.value.split(","),
      }
    };
    props.onChange(event);
  }

  const handleUpdateChip = chips => {
    let event = {
      target: {
        id: props.id,
        value: chips
      }
    };
    props.onChange(event);
  }
  const handleDeleteChip = (chip, index) => {
    let newArr = props.value
    newArr.splice(index, 1)
    let event = {
      target: {
        id: props.id,
        value: newArr
      }
    };
    props.onChange(event);
  }
  var childProps = Object.assign({},props);
  delete childProps.type;
  delete childProps.onChange;
  if (props.type === 'integer') {
    return (
      <TextField
        type="number"
        onChange={props.onChange}
        {...childProps}
      />
    );
  } 
  else if (props.type === 'array') {
    // TODO: We might want to do this with Chips, but the library we were using is not
    // supported anymore. We can do it like https://codesandbox.io/p/sandbox/material-ui-input-with-chips-0s2j4
    // Decided to not spend my time here since this is going away anyway
    return (
        <TextField
          type="text"
          onChange={handleUpdateList}
          {...childProps}
        />
    );
  } 
  else {
    return (
      <TextField
        type="text"
        onChange={props.onChange}
        {...childProps}
      />
    );
  }
}
