/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 
 http://www.apache.org/licenses/LICENSE-2.0
 
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';

import { setConfirmationModalOpen } from '../../actions/interactions';
import { connect } from 'react-redux';


const mapStateToProps = state => {
  return {
    show: state.portal.isConfirmationModalOpen.show,
    message: state.portal.isConfirmationModalOpen.message,
    onYes: state.portal.isConfirmationModalOpen.onYes,
    settings: state.portal.isConfirmationModalOpen.settings || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setCloseModal: () => dispatch(setConfirmationModalOpen({ show: false, message: null, onYes: function () { } })),
  };
}

const ConnectedConfirmationModal = props => {

  return (
    <Dialog
      onBackdropClick="false"
      open={props.show}
      onClose={props.setCloseModal}
      aria-labelledby="trash-dialog-title"
      fullWidth
      maxWidth={props.settings.maxWidth || 'xs'}
    >
      <DialogTitle>{props.settings.title}</DialogTitle>
      <DialogContent>
        <Typography>{props.message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.setCloseModal}>
          {props.settings.cancelTxt || 'No'}
        </Button>
        <Button 
          onClick={props.onYes}
          color='secondary'
          >
          {props.settings.confirmText || 'Yes'}
          </Button>
      </DialogActions>
    </Dialog>
  )
}

const ConfirmationModal = connect(mapStateToProps, mapDispatchToProps)(ConnectedConfirmationModal)

export default ConfirmationModal;
