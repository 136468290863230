/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */


import React, { useState, useEffect }from 'react';
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";

import { editUserInfo, changePassword } from '../utils/Cachengo';
import { checkLogin } from '../actions/user'
import { displayRequestError, setSnackBarMessage } from '../actions/interactions';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    padding: theme.spacing(1,0, 1.5),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  orgsTitle: {
    paddingTop: theme.spacing(2),
  },
  icon: {
    fontSize: '120px',
    color: theme.palette.primary.main,
    backgroundColor: '#eaeaea',
    borderRadius: '50%',
    padding: theme.spacing(.5),
    margin: theme.spacing(1),
  },
  values: {
    padding: theme.spacing(1),
  },
  rSide: {
    padding: theme.spacing(0,2),
  },
  inputsDiv: {
    maxWidth: '500px'
  },
  orgItem: {
    padding: theme.spacing(1,0),
    margin: theme.spacing(.5,0),
  },
  btns: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  }
}));

const mapStateToProps = state => {
  return {
    currentUser: state.oidc.user,
    organizations: state.portal.organizations,	
  }
}


function mapDispatchToProps(dispatch) {
  return {
    resetAuth: () => dispatch(checkLogin()),
    displayRequestError: (res) => dispatch(displayRequestError(res)),
    setSnackBarMessage: (message, type) => dispatch(setSnackBarMessage(message, type))
  };
}

const ConnectedProfile = props => {
  const classes = useStyles();
  const [isInfoEditing, setInfoEdit] = useState(false)
  const [isPasswordEditing, setPasswordEdit] = useState(false)
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    resetFormValues()
  }, []);

  const resetFormValues = () => {
    setFormValues({
      ...formValues,
      username: props.currentUser.profile.preferred_username,
      name: props.currentUser.profile.name,
      email: props.currentUser.profile.email,
      public_key: props.currentUser.public_key,
    })
  }
  
  const handleCancell = () => {
    resetFormValues()
    toggleEdit()
  }
  const toggleEdit = () => {
    setInfoEdit(!isInfoEditing)
  }

  const handleInputChange = name => event => {
    setFormValues({
      ...formValues,
      [name]: event.target.value
    })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    editUserInfo(
      formValues.name || '',
      formValues.email || '',
      formValues.public_key ||'',
      props.displayRequestError
    ).then(res => {
      if (res && res.success) {
        toggleEdit()
        props.resetAuth()
        props.setSnackBarMessage('User information successfully updated', 'success')		
      }
    });

  }

  const handleCancellPW = ()=>{
    setPasswordEdit(false)
    setFormValues({
      ...formValues,
      password: '',
      confirmPW: '',
    })
  }

  const checkPasswordMatch = ()=>{
    if (!formValues.password || !formValues.password === ''){
      return false;
    }
    if (formValues.password !== formValues.confirmPW){
      return false;
    }
    return true
  }

  const handleChangePW = () => {
    changePassword(formValues.password)
    .then(res => {
      if (res && res.success) {
        setPasswordEdit(false)
        props.setSnackBarMessage('Password successfully updated', 'success')
      }else {
        props.setSnackBarMessage('Error Updating Password', 'error')
      }
    });
  }

  return (
    <div>
      <Typography variant='h5' className={classes.heading}>Profile</Typography>
      <Grid container spacing={2} >
        <Grid item xs={12} sm={3} lg={2} style={{display:'flex', justifyContent:'center'}}>
          <div className={classes.card}>
            <CardContent>
              <PersonRoundedIcon className={classes.icon} />
              <Typography align='center'>{props.currentUser.profile.preferred_username}</Typography>
            </CardContent>
            <CardActions>
              <Button size="small" 
                color='secondary'
                onClick={toggleEdit}
                disabled={isInfoEditing}
                startIcon={ <EditIcon />}
              >
                Edit Info
              </Button>
            </CardActions>
            <Button size="small"
              color='secondary'
              onClick={() => { setPasswordEdit(true) }}
              disabled={isPasswordEditing}
            >
              Change Password
            </Button>
          </div>
        </Grid>
        <Grid item sm={1} lg={1} className={classes.rSide}></Grid>
        <Grid item sm={8} lg={4} className={classes.rSide}>
          <div className={classes.inputsDiv}>
            <TextField
              margin="dense"
              fullWidth
              id="username"
              label="Username"
              name="username"
              value={formValues.username || ''}
              color='secondary'
              disabled={true}
            />
            <TextField
              autoFocus
              margin="dense"
              fullWidth
              id="name"
              label="Name"
              name="name"
              value={formValues.name || ''}
              onChange={handleInputChange('name')}
              color='secondary'
              disabled={true}
            />
            <TextField
              margin="dense"
              fullWidth
              id="email"
              type='email'
              label="Email Address"
              name="email"
              value={formValues.email || ''}
              onChange={handleInputChange('email')}
              color='secondary'
              disabled={true}
            />
            <TextField
              margin="dense"
              fullWidth
              id="public_key"
              label="Public Key"
              name="public_key"
              value={formValues.public_key || ''}
              onChange={handleInputChange('public_key')}
              color='secondary'
              multiline
              rows='4'
              disabled={!isInfoEditing}
            />
          
            {isInfoEditing && 
              <div className={classes.btns}>
                <Button
                  style={{marginRight: '10px'}}
                  size="small"
                  onClick={handleCancell}
                >
                  {"Cancel"}
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color='secondary'
                  onClick={handleSubmit}
                  startIcon={<SaveIcon />}
                  disableElevation
                >
                  {"Save user info"}
                </Button>
              </div>
            }
          </div>

          <div>
            {isPasswordEditing && 
              <div>
              <Typography className={classes.orgsTitle} variant='h6'>Change Password</Typography>
                <TextField
                  margin="dense"
                  required={true}
                  fullWidth
                  name="password"
                  label="New Password"
                  type="password"
                  id="password"
                  value={formValues.password || ''}
                  onChange={handleInputChange('password')}
                  color='secondary'
                />
                <TextField
                  margin="dense"
                  required={true}
                  fullWidth
                  name="confirmPW"
                  label="Confirm New Password"
                  type="password"
                  id="confirmPW"
                  value={formValues.confirmPW || ''}
                  onChange={handleInputChange('confirmPW')}
                  color='secondary'
                />
                <div className={classes.btns}>
                  <Button
                    style={{marginRight: '10px'}}
                    size="small"
                    onClick={handleCancellPW}
                  >
                    {"Cancel"}
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color='secondary'
                    onClick={handleChangePW}
                    disabled={!checkPasswordMatch()}
                    startIcon={<SaveIcon />}
                    disableElevation
                  >
                    {"Update password"}
                  </Button>
                </div>
              </div>
            }
          </div>

          <Typography className={classes.orgsTitle} variant='h6'>Organizations</Typography>
          {props.organizations.map((org, index) => (
            <div className={classes.orgItem} key={index}>
              <Typography variant='body1'>{org.name}</Typography>
            </div>
          ))}

        </Grid>
      </Grid>
    </div>
  );
}


const Profile = connect(mapStateToProps, mapDispatchToProps)(ConnectedProfile);

export default Profile;
