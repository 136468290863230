import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from "@material-ui/core/Tooltip";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CloseIcon from '@material-ui/icons/Close';
import AppsIcon from '@material-ui/icons/Apps';
import RouterIcon from '@material-ui/icons/Router';
import Button from '@material-ui/core/Button';

import { removeItemFromInstallation, connectSwitch, removeSingleDevice } from '../actions/installation';
import { setSnackBarMessage } from '../actions/interactions';

const useStyles = makeStyles((theme) => ({
  root: {
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '4px',
  },
  },
  txt:{
    whiteSpace: 'nowrap'
  },
  item: {
    display: 'flex',
    alignItems: 'center'
  },
  devicesDiv: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  device: {
    display: 'flex',
    borderRadius: '5px',
    alignItems: 'center',
    paddingRight: '16px'
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconBg:{
    display:'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  iconSm: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  Btntypo: {
    fontSize: '.9em',
    textTransform: 'initial',
    marginTop: '-5px',
    padding: '0 10px',
    whiteSpace: 'nowrap',
  }
}));

const mapStateToProps = state => {
  return {
    item: state.portal.itemToInstall,
    devices: state.portal.devicesToInstall
  }
}

function mapDispatchToProps(dispatch) {
  return {
    removeItemFromInstallation: () => dispatch(removeItemFromInstallation()),
    connectSwitch: (handle, switch_id) => dispatch(connectSwitch(handle, switch_id)),
    removeSingleDevice: (handle) => dispatch(removeSingleDevice(handle)),
    setSnackBarMessage: (message, type) => dispatch(setSnackBarMessage(message, type))
  };
}

const ConnectedTableInstall = props => {
  const classes = useStyles();
  const history = useHistory()

  if (!props.item.type && props.devices.length < 1) {
    return (
      <div style={{padding:'8px 0'}}>
      <Typography variant='body2' >
        To start an Installation, select Devices and Apps or Peergroups
      </Typography>
      </div>
    );
  }

  const handleInstall = () => {
    let devices = props.devices.filter(device => device.status !== 'disabled')
    let disabledDevices = props.devices.length - devices.length;

    if (disabledDevices > 0){
      let message = 'Please remove the disabled devices to continue installation';
      props.setSnackBarMessage(message, 'warning')
      return
    }

    if (props.item.type === 'application') {
      history.push('/installations')
    } else if (props.item.type === 'switch') {
      let handles = devices.map(device => device.handle)
      props.connectSwitch(handles, props.item.switch_id)
    }
  }

  const removeItem = ()=>{
    props.removeItemFromInstallation()
    
  }
  const removeDevice = (device) => {
    props.removeSingleDevice(device.handle)
  }
  
  return (
    <div>
      <Typography variant='subtitle2'>Installation Summary</Typography>
      <div className={classes.root} >
        {!props.item.type ?
          <Typography className={classes.txt} variant='body2' >Please Select App or Peer Group</Typography>
          : <div className={classes.item}>
            {props.item.type === "application" ?
              <AppsIcon
                color='secondary'
                style={{ marginRight: '4px' }}
              />
              : props.item.type === "switch" ?
                <RouterIcon
                  color='secondary'
                  style={{ marginRight: '4px', marginTop: '-5px' }}
                />
                : null}
              <Typography className={classes.txt} variant='body1'>{props.item.name}</Typography>
            <IconButton
              onClick={removeItem}
              size='small'
              aria-label="delete-item"
              style={{ marginLeft: '8px' }}
            >
              <CloseIcon fontSize='small' />
            </IconButton>

          </div>}

        <div style={{ margin: '0 8px' }}>
          <Button
            onClick={handleInstall}
            color="secondary"
            size='small'
            disabled={!props.item.type || props.devices.length < 1}
          >
            <div className={classes.button}>
              <ArrowRightAltIcon className={classes.iconBg}/>
              <ArrowDownwardIcon className={classes.iconSm}/>
              <Typography className={classes.Btntypo}>Install Now</Typography>
            </div>
          </Button>
        </div>

        <div className={classes.devicesDiv}>
          {props.devices.length < 1 && 
            <Typography variant='body2' >Please Select Devices</Typography> }
          {props.devices.map(device => (
            <div key={device.handle}
              className={classes.device}
            >
              {device.status !== 'disabled' ? 
                <Typography variant='body1'>{device.name}</Typography>
                : <Tooltip title={'Device is disabled'} placement="top" >
                  <Typography variant='body1' color='error'>{device.name}</Typography>
                </Tooltip>
              }
              <IconButton
                onClick={() => removeDevice(device)}
                size='small'
                aria-label="delete-device"
                style={{ marginLeft: '8px' }}
              >
                <CloseIcon fontSize='small' />
              </IconButton>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const InstallTable = connect(mapStateToProps, mapDispatchToProps)(ConnectedTableInstall);

export default InstallTable;
