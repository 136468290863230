/*
 Copyright 2024, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore } from "oidc-client";
import {
  AUTH_DOMAIN,
  AUTH_CLIENT_ID,
  AUTH_PROJECT_RESOURCE_ID,
} from './Constants';

// TODO: Check if settings are set

const currentUri = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

export const isSuperuser = user => {
  const role_claim = `urn:zitadel:iam:org:project:${AUTH_PROJECT_RESOURCE_ID}:roles`;
  const roles = user.profile ? user.profile[role_claim] : null;

  return (roles && 'admin:portal' in roles);
};

export const isManufacturer = user => {
  const role_claim = `urn:zitadel:iam:org:project:${AUTH_PROJECT_RESOURCE_ID}:roles`;
  const roles = user.profile ? user.profile[role_claim] : null;

  return (roles && 'manufacturer:org' in roles);
};

export const isAdmin = (user, org) => {
  const role_claim = `urn:zitadel:iam:org:project:${AUTH_PROJECT_RESOURCE_ID}:roles`;
  const roles = user.profile ? user.profile[role_claim] : null;
  if (!roles)
      return false;

  const admin_orgs = roles['admin:org'];

  return (admin_orgs && org in admin_orgs)
};

const userManagerConfig = {
  authority: AUTH_DOMAIN,
  automaticSilentRenew: true,
  client_id: AUTH_CLIENT_ID,
  filterProtocolClaims: true,
  loadUserInfo: false,
  redirect_uri: `${currentUri}/callback`,
  post_logout_redirect_uri: `${currentUri}`,
  response_type: "code",
  scope: `openid profile email offline_access ` +
         `urn:zitadel:iam:org:project:id:zitadel:aud ` +
         `urn:zitadel:iam:org:project:id:${AUTH_PROJECT_RESOURCE_ID}:aud ` +
         `urn:zitadel:iam:org:projects:roles`,
  response_mode: "query",
};

export const userManager = createUserManager({
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  ...userManagerConfig
});

export default userManager;
