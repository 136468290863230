/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
import React, {Component} from 'react';
import { FormControl } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { setOrganization, updateOrgList } from '../actions/organization';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    selectedOrg: state.portal.selectedOrg,
    organizations: state.portal.organizations
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateOrgList: () => dispatch(updateOrgList()),
    setOrg: (payload) => dispatch(setOrganization(payload))
  };
}

class ConnectedOrganizationList extends Component {

  constructor(props) {    
    super(props);

    this.handleListItem = this.handleListItem.bind(this);
  }

  componentDidMount() {
    this.props.updateOrgList()
  }

  handleListItem = (event, child) => {
    var handle = event.target.value;
    
    if (typeof(this.props.onChange) != 'undefined'){
      this.props.onChange(handle);
    } else {
      this.props.setOrg(this.props.organizations[child.key]);
    }
  }

  render() {
    return (
      <FormControl fullWidth>
        <Select
          onChange={this.handleListItem}
          disableUnderline={true}
          value={this.props.value || this.props.selectedOrg.handle}
        >
          {this.props.organizations.map((option, index) => (
          <MenuItem
            value={option.handle}
            key={index}
          >
            {option.name}
          </MenuItem>))}
        </Select>
      </FormControl>
    )
  }
}

const OrganizationList = connect(mapStateToProps, mapDispatchToProps)(ConnectedOrganizationList)

export default OrganizationList;
