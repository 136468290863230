/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Tooltip from "@material-ui/core/Tooltip";

import instructions from '../utils/CreateAppInstructions.md';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2)
  },
}));

function HowToCreateApp(props) {
  const classes = useStyles();
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch(instructions).
    then((res) => res.text())
    .then((text) => {
      setContent(text)
    })
  }, [])

  const codeHighlighter = ({ value, language }) => {
    return (
      <SyntaxHighlighter language={language ?? null} style={docco}>
        {value ?? ''}
      </SyntaxHighlighter>
    );
  }
  const goBack = ()=>{
    props.history.push('/new_app')
  }

  return (
    <div className={classes.root}>
      <Tooltip title={'How to create an Application'} placement="top">
        <IconButton
          style={{marginLeft: '-16px'}}
          onClick={goBack}
          aria-label={`go-back-to-create-new-app`}
        >
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
      <ReactMarkdown
        source={content}
        className="markdown"
        renderers={{
          code: codeHighlighter,
        }}
      />
    </div>
  )
}

export default HowToCreateApp;
