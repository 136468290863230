/*
Copyright 2019, Cachengo, Inc.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React, {useState, useEffect} from 'react';
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import ImageIcon from '@material-ui/icons/Image';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox';
import NumberFormatCustom from './NumberFormatCustom'
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from "@material-ui/core/Tooltip";

import { createApp, getUploadToken, uploadFile, removeApp } from '../utils/Cachengo';
import ParameterCard from './ParameterCard';
import FilePicker from './FilePicker';
import { capitalizeAllWords } from '../utils/Helpers';


const useStyles = makeStyles(theme => ({
  root: {},
  hide: {
    display: 'none'
  }

}))

const mapStateToProps = state => {
  return {
    org: state.portal.selectedOrg,
    categories: state.portal.categories,
  };
};

const ConnectedNewApp = (props) => {
  const classes = useStyles();
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [category, setCategory] = useState('other')
  const [price, setPrice] = useState('0.00')
  const [isPublic, setPublic] = useState(false)
  const [SSHInstall, setSSHInstall] = useState(false)
  const [url, setUrl] = useState('')
  const [parameters, setParameters] = useState([])
  const [fileInputMessage, setFileInputMessage] = useState('Drop a file or click to browse')
  const [logoInputMessage, setLogoInputMessage] = useState('Drop a logo or click to browse')
  const [fileErrorMessage, setFileErrorMessage] = useState('')
  const [newParam, setNewParam] = useState(false)
  const [isReadme, setReadme] = useState(false)
  const [fileReadmeMessage, setFileReadmeMessage] = useState('Drop a README file or click to browse')
  const [fileList, setFileList] = useState({})


  useEffect(() => {
    if (props.location.isPublic){
      setPublic(true)
    }
  }, []);

  const handleErrorMessages = (errorMessage, errorComponent) => {
    let errorIcon = <WarningIcon fontSize="large" style={{ color: 'red' }} />
    setFileErrorMessage(errorMessage)
    if(errorComponent === 'file') {
      setFileInputMessage(errorIcon)
    } else if (errorComponent === 'logo') {
      setLogoInputMessage(errorIcon)
    } else if (errorComponent === 'md') {
      setFileReadmeMessage(errorIcon)
    }
  }

  const handleFileInput = (acceptedFiles) => {
    setFileList(Object.assign({}, fileList, {"recipe": acceptedFiles[0]}))
    let message = <CloudDoneIcon fontSize="large" style={{ color: 'green' }} />
    setFileInputMessage(message)
  }

  const handleReadmeFileInput = (acceptedFiles) => {
    setFileList(Object.assign({}, fileList, {"md": acceptedFiles[0]}))
    let readmeMessage = <CloudDoneIcon fontSize="large" style={{ color: 'green' }} />
    setFileReadmeMessage(readmeMessage)
  }

  const handleLogoInput = (acceptedFiles) => {
    setFileList(Object.assign({}, fileList, {"logo": acceptedFiles[0]}))
    let logoMessage = <ImageIcon fontSize="large" style={{ color: 'green' }} />
    setLogoInputMessage(logoMessage)
  }

  const addParamIfOutside = (current_params, key) => {
    if (key === current_params.length) {
      setNewParam(false)
      current_params[key] = {
        display_name: "",
        description: "",
        category: "",
        default_value: "",
        parameter_type: "string",
        required: false,
        env_var: ""
      };
    }
  }

  const handleChangeParameterInput = key => arg => event => {
    const target = event.target;
    const value = target.value;
    const current_params = [...parameters];
    addParamIfOutside(current_params, key);
    current_params[key][arg] = value;
    setParameters(current_params)
  }

  const handleChangeParameterType = key => event => {
    const target = event.target;
    const current_params = [...parameters];
    addParamIfOutside(current_params, key)
    current_params[key].parameter_type = target.value;
    if(target.value === 'array'){
      current_params[key].default_value = [];
    }
    setParameters(current_params)
  }

  const handleSwitchChange = key => event => {
    const target = event.target;
    const current_params = [...parameters];
    addParamIfOutside(current_params, key)
    current_params[key].required = target.checked
    setParameters(current_params)
  }

  const handleDeleteParameterCard = key => () => {
    const current_params = [...parameters];
    current_params.splice(key, 1);
    setParameters(current_params);
  }
  const handleSubmit = () => {
    var parsedParams = Object.keys(parameters).map(function (key) {
      let param = parameters[key];
      var parsedValue = param.default_value;
      if (param.parameter_type === 'integer') {
        parsedValue = parseInt(param.default_value);
      }
      if (parsedValue === '') {
        parsedValue = null
      }
      return Object.assign({}, param, { default_value: parsedValue })
    })
    let parsedPrice = parseFloat(price)
    createApp(name, isPublic, url, parsedPrice, category, description, parsedParams, props.org, SSHInstall)
      .then(res => {
        let app_handle = res.app_id
        var uploadFuncs = Object.keys(fileList).map((k, v) => {
            return getUploadToken(app_handle, k).then(minioResp => {
                let bucketUrl = minioResp.token.url
                let mininoFields = minioResp.token.fields
                return uploadFile(bucketUrl, fileList[k], mininoFields)
            })
        })
        return Promise.all(uploadFuncs).then(
            props.history.push('/applications')
        ).catch((e) => {
            console.log(e)
            removeApp([app_handle])
        })
      }).catch((e) => {
        console.log(e)
    });
  }

  const gotoInstructionNewApp = () => {
    props.history.push('/instructions_create_app')
  }

  let isValid = !(name === '' || description === '' || fileList.file != null );
  let temp_parameters = []
  if (parameters.length > 0){
    temp_parameters = parameters.map((param, index) => {
      let thisIsValid = !(param.display_name === "" || param.description === "" || param.parameter_type === "" || param.env_var === "")
      isValid = (thisIsValid && isValid);
      return (
        <ParameterCard key={index}
          index={index}
          has_error={!thisIsValid}
          display_name={parameters[index].display_name}
          description={parameters[index].description}
          default_value={parameters[index].default_value}
          parameter_type={parameters[index].parameter_type}
          required={parameters[index].required}
          env_var={parameters[index].env_var}
          handleChangeParameterInput={handleChangeParameterInput(index)}
          handleChangeParameterType={handleChangeParameterType(index)}
          handleSwitchChange={handleSwitchChange(index)}
          handleDeleteParameterCard={handleDeleteParameterCard(index)}
        />
      )
    })
  }
  if(parameters.length === 0 || newParam) {
    temp_parameters.push(
      <ParameterCard key={parameters.length}
        index={parameters.length}
        display_name=""
        description=""
        default_value=""
        parameter_type="string"
        required={false}
        env_var=""
        handleChangeParameterInput={handleChangeParameterInput(parameters.length)}
        handleChangeParameterType={handleChangeParameterType(parameters.length)}
        handleSwitchChange={handleSwitchChange(parameters.length)}
      />
    )
  }

  const categories = props.categories.map(x => ({
    value: x,
    label: capitalizeAllWords(x)
  }));

  return (
    <Box py={1} px={2} mt={1.5}>
      <div style={{display:'flex', alignItems:'center'}}>
        <Typography variant='h5' style={{ padding: '15px 15px 15px' }}>
          Create a new application
        </Typography>
        <Tooltip title={'How to create an Application'} placement="top">
          <IconButton
            size='small'
            onClick={gotoInstructionNewApp}
            aria-label={`instruction-on-how-to-create-an-app`}
          >
            <HelpIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Typography style={{ paddingLeft: '15px' }} variant='body1'>General Information</Typography>
        <Grid container spacing={2} style={{ padding: '10px 25px 20px' }}>
          <Grid item sm={6}>
            <TextField
              autoFocus
              margin="dense"
              label="Application Name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Application Name"
              fullWidth
              color='secondary'
            />
            <TextField
              margin="dense"
              label="App Description"
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="App Description"
              fullWidth
              multiline
              rows={3}
              rowsMax={8}
              color='secondary'
            />
            <TextField
              margin="dense"
              label="App Category"
              select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              placeholder="App Category"
              fullWidth
              multiline
              rows={3}
              rowsMax={8}
              color='secondary'
            >
              {categories.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item sm={6} container style={{ paddingLeft: '55px' }}>
            <Grid item sm={12} style={{ paddingTop: '5px' }}>
              <FormControlLabel
                control={<Checkbox
                  checked={SSHInstall}
                  onChange={(e) => setSSHInstall(e.target.checked)}
                name="SSHInstall" />}
                label="SSH installation"
              />
            </Grid>
            <Grid item style={{ paddingTop: '0px' }}>
              <FormControlLabel
                control={<Checkbox
                  checked={isPublic}
                  onChange={(e) => setPublic(e.target.checked)}
                  name="checkedA" />}
                label="Public"
              />
            </Grid>
            <Grid item sm={6} style={{ paddingTop: '0px', paddingLeft: '15px' }}>
              <Tooltip title="Paid apps coming soon">
              <TextField
                className={clsx({ [classes.hide]: !isPublic})}
                InputLabelProps={{ style: { pointerEvents: "auto" } }}
                label="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                name="numberformat"
                id="formatted-numberformat-input"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                fullWidth
                color='secondary'
                disabled
              />
              </Tooltip>
            </Grid>
            <Grid item sm={12} >
              <TextField
                className={clsx({ [classes.hide]: !isPublic })}
                margin="dense"
                label="Application Url"
                type="text"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Application Url"
                fullWidth
                color='secondary'
              />
            </Grid>
          </Grid>
        </Grid>
      <Typography style={{ paddingLeft: '15px' }} variant='body1'>Files</Typography>
        <Grid container spacing={2} style={{ padding: '15px 25px 35px' }}>
          <Grid item sm={6} >
            <Typography variant='body2'>Application File:</Typography>
            <FilePicker message={fileInputMessage} handleFileInput={handleFileInput} />
          </Grid>
          <Grid item sm={6} >
            <Typography variant='body2'>Application Logo:</Typography>
            <FilePicker message={logoInputMessage} handleFileInput={handleLogoInput} accept="image/*" />
          </Grid>
          <Snackbar open={fileErrorMessage.length > 0} onClose={handleErrorMessages.bind(this, "")}>
            <SnackbarContent
              style={{ backgroundColor: 'red' }}
              message={fileErrorMessage}
              action={
                <IconButton onClick={handleErrorMessages.bind(this, "")}>
                  <CloseIcon />
                </IconButton>
              }
            />
          </Snackbar>
          <FormControlLabel
            control={
              <Switch
                checked={isReadme}
                onChange={(e) => setReadme(e.target.checked)}
                name="checkedReadme"
                color="secondary"
              />
            }
            style={{ paddingLeft: '25px', fontSize: '10px'}}
            label="Add README"
          />
          <Grid container spacing={2} justify="center" className={clsx({ [classes.hide]: !isReadme })}>
            <Grid item sm={10}>
                <Typography variant='body2'>README file:</Typography>
                <FilePicker message={fileReadmeMessage} handleFileInput={handleReadmeFileInput} />
            </Grid>
          </Grid>
        </Grid>
        <Typography style={{ padding: '0 15px 15px' }} variant='body1'>Parameters</Typography>
        {temp_parameters}
        <Button
          style={{ marginLeft: '15px' }}
          onClick={()=>{setNewParam(true)}}
        >
          Add another parameter
        </Button>
      <DialogActions>
        <Button
          color='secondary'
          variant='contained'
          onClick={handleSubmit}
          disabled={!isValid}
        >
          Create Application
        </Button>
      </DialogActions>
    </Box>
  )
}

const NewApp = connect(mapStateToProps, null)(ConnectedNewApp);

export default NewApp;
