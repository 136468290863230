/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React, { useState, useEffect }  from 'react';
import { useParams, useHistory } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import AssessmentIcon from '@material-ui/icons/Assessment';
import DnsIcon from '@material-ui/icons/Dns';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Divider from '@material-ui/core/Divider';
import AppsIcon from '@material-ui/icons/Apps';
import LaunchIcon from '@material-ui/icons/Launch';

import InstalledSwitches from './InstalledSwitches';
import InstalledApps from './InstalledApps';
import { connect } from 'react-redux';
import { isAdmin } from '../utils/userManager';
import { getDeviceInfo, getInstallations } from '../utils/Cachengo';
import { removeEventHandler, addEventHandler } from '../actions/items';
import Metrics from './Metrics';
import DeviceGeneralInfo from './DeviceGeneralInfo';
import DeviceEditModal from './modals/DeviceEditModal';



const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(17),
      fontWeight: theme.typography.fontWeightRegular,
    },
    border: {
        boxShadow: "None"
    },
    metricsGrid:{
      [theme.breakpoints.up('sm')]: {
        margin: "10px", 
        padding: "10px"
      },
    }
}));

const mapStateToProps = state => {
  return {
    org: state.portal.selectedOrg,
    currentUser: state.oidc.user
  }
}

function mapDispatchToProps(dispatch) {
  return { 
    removeEventHandler: (key) => dispatch(removeEventHandler(key)),
    addEventHandler: (key, handler) => dispatch(addEventHandler(key, handler))
  };
}

function ConnectedDeviceInfo(props) {
  const classes = useStyles();
  const [deviceInfo, setDeviceInfo] = useState([]);
  const [isEditModalOpen, setEditModal] = React.useState(false)
  const [installedApps, setInstallations ] = useState([]);
  const { id } = useParams();
  const history = useHistory()

  useEffect(() => {
    handleRefreshData()
  }, [id])

  useEffect(() => {
    props.addEventHandler('deviceInfo', (message) => {
      if (message.event === 'connect_switch' || message.event === 'disconnect_switch' || message.event === 'edit_device_info') {
        if (message.target === id) {
          getDeviceInfo(id).then(res => setDeviceInfo(res.info[0]))
        }
      }
    })
    return () => props.removeEventHandler('deviceInfo')
  }, [id])

  useEffect(() => {
    props.addEventHandler('installInfo', (message) => {
      if (message.event === 'update_install_state' || message.event === 'edit_device_info') {
        if (message.target === id) {
          getInstallations(id).then(res => {
            if (res) {
              setInstallations(res.installations)
            }
          })
        }
      }
    })
    return () => props.removeEventHandler('installInfo')
  }, [id])

  const handleRefreshData = ()=> {
    getDeviceInfo(id).then(res => res && setDeviceInfo(res.info[0]))
    getInstallations(id).then(res => {
      if (res) {
        setInstallations(res.installations)
      }
    })
  }
  const goBack = () => {
    if(props.location.isInternal){
      history.goBack()
    }else{
      history.push('/')
    }
  }
  const toggleEditInfoModal = () => {
    setEditModal(!isEditModalOpen);
  }

  const openTerminal = (name, handle) => {
    let idUrl = `/terminal/${name}/${handle}`
    history.push(idUrl);
  }

  const refreshTable = ()=> {
    getDeviceInfo(id).then(res => setDeviceInfo(res.info[0]))
  }
  
  return (
    <Grid style={{ marginTop: "10px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Device Info</Typography>
        <div>
          {isAdmin(props.currentUser, props.org.handle) &&
          <Tooltip title={"Open Shell"} placement="top">
            <IconButton
              onClick={() => openTerminal(deviceInfo.name, deviceInfo.handle)}
              aria-label={`refresh-data`}
            >
              <LaunchIcon />
            </IconButton>
          </Tooltip>
          }
          <Tooltip title={"Edit device info"} placement="top">
            <IconButton
              onClick={toggleEditInfoModal}
              aria-label={`refresh-data`}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Refresh info"} placement="top">
            <IconButton onClick={handleRefreshData} aria-label="refresh-btn">
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <IconButton onClick={goBack} aria-label="close-btn">
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div style={{ paddingTop: "10px" }}>
        <Accordion className={classes.border}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <AssignmentIcon color="secondary" style={{marginRight: "5px"}}/>
            <Typography className={classes.heading} fontWeight="fontWeightBold">
              General Information
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DeviceGeneralInfo
              toggleEditInfoModal={toggleEditInfoModal}
              name={deviceInfo.name}
              handle={deviceInfo.handle}
              last_seen={deviceInfo.last_seen}
              mac={deviceInfo.mac}
              private_ip={deviceInfo.private_ip}
              status={deviceInfo.status}
              is_vm={deviceInfo.is_vm}
              ram={deviceInfo.ram}
              cores={deviceInfo.cores}
              sled_position={deviceInfo.sled_position}
              sled_number={deviceInfo.sled_number}
              product_type={deviceInfo.product_type}
              version={deviceInfo.version}
            />
          </AccordionDetails>
        </Accordion>
      </div>
      <Divider style={{ margin: "10px" }}/>
      <div style={{ paddingTop: "10px" }}>
        <Accordion className={classes.border}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <AssessmentIcon color="secondary" style={{marginRight: "5px"}}/>
            <Typography className={classes.heading} fontWeight="fontWeightBold">
              Metrics
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={4}
              className={classes.metricsGrid}
            >
              <Grid item md={6}>
                <Metrics metricType="cpu" graphTitle="CPU" />
              </Grid>
              <Grid item md={6}>
                <Metrics metricType="ram" graphTitle="RAM" />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
      <Divider style={{ margin: "10px" }}/>
      <div style={{ paddingTop: "10px" }}>
        <Accordion className={classes.border}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <DnsIcon color="secondary" style={{marginRight: "5px"}}/>
            <Typography className={classes.heading}>
              Peer Groups
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InstalledSwitches
              connections={deviceInfo.connections || []}
              handle={deviceInfo.handle}
            />
          </AccordionDetails>
        </Accordion>
      </div>
      <Divider style={{ margin: "10px" }}/>
      <div style={{ paddingTop: "10px" }}>
        <Accordion className={classes.border}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <AppsIcon color="secondary" style={{marginRight: "5px"}}/>
            <Typography className={classes.heading}>
              Private Registry
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InstalledApps
              apps={installedApps || []}
              handle={deviceInfo.handle}
            />
          </AccordionDetails>
        </Accordion>
      </div>
      <Divider style={{ margin: "10px" }}/>
      <DeviceEditModal
        name={deviceInfo.name}
        handle={deviceInfo.handle}
        show={isEditModalOpen}
        handleClose={toggleEditInfoModal}
        refreshTable={refreshTable}
      />
    </Grid>
  ); 
}

const DeviceInfo = connect(mapStateToProps, mapDispatchToProps)(ConnectedDeviceInfo);

export default DeviceInfo;
