/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from "@material-ui/core/Tooltip";
import AddBoxIcon from '@material-ui/icons/AddBox';
import RefreshIcon from '@material-ui/icons/Refresh';
import Button from "@material-ui/core/Button";

import DeleteIcon from '@material-ui/icons/Delete';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import Divider from '@material-ui/core/Divider';

import { setConfirmationModalOpen } from '../actions/interactions';
import { removeItemFromInstallation } from '../actions/installation';
import { isEmpty } from '../utils/Helpers';
import SearchBar from './SeachBar';

const useStyles = makeStyles(theme => ({
  root: {
  },
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap:'wrap',
  },
  actionRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'space-between',
  },
}));

const mapStateToProps = state => {
  return {
    show: state.portal.isConfirmationModalOpen.show,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    removeItemFromInstallation: () => dispatch(removeItemFromInstallation()),
    openConfirmationModal: (item) => dispatch(setConfirmationModalOpen(item)),
  };
}

const ConnectedCustomTableHead = props => {
  const classes = useStyles();

  const {
    createNewItemAction = {},
    refreshItemAction = {},
    installItemAction = {},
    removeItemAction = {},
    actions = [],
    handleRefreshData,
    title,
    titleVariant,
    selectedItems={},
    clearSelectedItems,
    handleSearch,
    searchOptions
  } = props

  const handleDeleteItem = (rowData) => {
    return removeItemAction.handleRemove(rowData)
      .then(() => {
        handleRefreshData()
        clearSelectedItems()
      });
  }

  const installItemIcon = (props) => (
    <PlayForWorkIcon {...props} />
  );

  const removeItemIcon = (props) => (
    <DeleteIcon {...props} />
  );

  let defaultActions = [
    {
      icon: installItemIcon,
      tooltip: installItemAction.tooltip,
      onClick: (event, rowData) => {
        installItemAction.handleInstall(rowData)
      }
    },
    {
      icon: removeItemIcon,
      tooltip: removeItemAction.tooltip,
      onClick: (event, rowData) => {
        props.openConfirmationModal(
          {
            show: true,
            message: removeItemAction.message,
            onYes: () => {
              handleDeleteItem(rowData)
                .then(props.openConfirmationModal({ show: false, message: null, onYes: function () { } }))
            }
          }
        );
      }
    },
  ]

  const TableActions = [
    ...defaultActions.filter((obj) => typeof obj.tooltip != "undefined"),
    ...actions
  ];

  return (
    <div className={classes.root}>

      <section className={classes.headerRoot}>
        <Typography variant={titleVariant || 'h6'}>{title}</Typography>
        <div style={{ flexGrow: 1}}></div>
        {searchOptions && <SearchBar
          handleSearch={handleSearch}
          searchOptions={searchOptions}
        />}
        <div>
          {createNewItemAction.handleCreateNew &&
            <Button
              color="secondary"
              startIcon={<AddBoxIcon />}
              onClick={createNewItemAction.handleCreateNew}
            >
              {createNewItemAction.tooltip}
            </Button>
          }
          {TableActions.map((item, i) => {
            let action;
            if (typeof item === 'function') {
              action = item(selectedItems)
            } else {
              action = item
            }
            let Icon = action.icon
            let { tooltip, onClick } = action;
            let disabled = isEmpty(selectedItems) ? true : !!action.disabled;

            return (
              <Tooltip title={tooltip} placement="top" key={i}>
                <span>
                  <IconButton
                    disabled={disabled}
                    onClick={(e) => onClick(e, selectedItems)}
                    aria-label={action.tooltip}
                  >
                    <Icon />
                  </IconButton>
                </span>
              </Tooltip>
            )
          })}
          {!refreshItemAction.hide &&
            <Tooltip title={refreshItemAction.tooltip || 'Refresh Data'} placement="top">
              <IconButton
                onClick={handleRefreshData}
                aria-label={`refresh-data`}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          }
        </div>
      </section>

      <Divider />
      <section className={classes.actionRoot}>
      </section>
    </div>
  )
}

const CustomTableHead = connect(mapStateToProps, mapDispatchToProps)(ConnectedCustomTableHead);

export default CustomTableHead;
