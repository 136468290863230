/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React, { useState, useEffect, useRef } from 'react';
import clsx from "clsx";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Items from './Items';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import { removeEventHandler, addEventHandler } from '../actions/items';
import { getAppsByCategory, fetchApps, get_marketplace_apps } from '../utils/Cachengo';
import { capitalizeAllWords } from '../utils/Helpers';

const useStyles = makeStyles(theme => ({
    root: {
      margin: theme.spacing(2, 0),
    },
    itemBox: {
      padding: theme.spacing(1),
      border: 'solid 1px',
      borderColor: theme.palette.divider,
      borderRadius: '5px',
      cursor: 'pointer',
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      width: "100%",
      height: "100%",
      transition: theme.transitions.create("box-shadow", {
        easing: theme.transitions.easing.sharp,
        duration: ".3s"
      }),
      "&:hover": {
        boxShadow: theme.shadows[1],
      }
    },
    active: {
      borderColor: theme.palette.secondary.main,
      background: theme.palette.background.default,
    },
    itemBoxHeader: {
      width: "100%",
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: theme.spacing(2),
    },
    dataInfoDiv: {
      width: "100%",
      display: 'flex',
      justifyContent: 'space-between',
    },
    body: {
      padding: theme.spacing(4),
      display: 'none',
      overflow: 'hidden',
      maxHeight: '0px',
      transition: 'max-height .9s ease-out'
    },
    expIcon: {
      transition: 'transform .2s ease-out'
    },
    showing: {
      transform: 'rotate(180deg)',
    },
    Productroot: {
      padding: theme.spacing(0,0,2)
    },
  }));

function mapDispatchToProps(dispatch) {
  return {
    removeEventHandler: (key) => dispatch(removeEventHandler(key)),
    addEventHandler: (key, handler) => dispatch(addEventHandler(key, handler)),
  };
}

const mapStateToProps = state => {
  return {
    categories: state.portal.categories
  };
};

const ConnectedApplication = function(props) {
    const classes = useStyles();
    const [show, setShow] = useState([false])
    const [apps, setApps] = useState({})

    useEffect(() => {
      props.addEventHandler('applications', (message) => {
        if (message.event === 'new_app' || message.event === 'remove_app' || message.event === 'edit_app') {
          refreshApps(message)
        }
      })
      return () => props.removeEventHandler('applications')
    }, [props, show])

    const refreshApps = (message) =>{
      get_marketplace_apps({"category": message.category}).then(res =>{
        if (res && res.success)
        {
          let appsCopy = {...apps};
          appsCopy[message.category] = res.apps;
          setApps(appsCopy);
        }
      });
    }

    const categories = props.categories.map(x => ({
      value: x,
      label: capitalizeAllWords(x)
    }));

    const toggleShow = (i) => {
      let state = [...show]
      state[i] = !show[i]
      setShow(state)
      if (state[i]) {
        const category = categories[i].value;
        get_marketplace_apps({"category": category}).then(res =>{
          let appsCopy = {...apps};
          appsCopy[category] = res.apps;
          setApps(appsCopy);
        });
      }
    }

    if (props.data === undefined) {
        return <div></div>
    }

    return (
        <div >
          {categories.map((category, i) => (
            <div className={classes.Productroot} key={i}>
              <div className={classes.head} style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  size='small'
                  onClick={() => toggleShow(i)}
                  aria-label={`expand-devices-todo-name`}
                  style={{marginLeft: '-8px'}}
                >
                  <ExpandMoreIcon className={clsx(classes.expIcon, { [classes.showing]: show[i] })} />
                </IconButton>

                <Typography variant='body1' style={{ paddingRight: '8px', fontWeight: 'bold' }}>{category.label}</Typography>
              </div>
              <Collapse in={show[i]}>
                <div style={{padding: '0 10px'}}>
                  {apps[category.value] ? <Items {...props} data={apps[category.value]}/> : <p>Loading...</p>}
                </div>
              </Collapse>
            </div>
          ))}
        </div>
    )
}

const MarketApplication = connect(mapStateToProps, mapDispatchToProps)(ConnectedApplication);
export default MarketApplication;
