/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React, { useState, useEffect, forwardRef, useImperativeHandle} from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

import { setConfirmationModalOpen, setSnackBarMessage } from '../actions/interactions';
import { isInSelectedItem } from '../utils/Helpers';

import CustomTableHeader from './CustomTableHead';
import CustomTableBody from './CustomTableBody';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1,0)
  }
}));

const mapStateToProps = state => {
  return {
    org: state.portal.selectedOrg,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    openConfirmationModal: (item) => dispatch(setConfirmationModalOpen(item)),
    setSnackBarMessage: (message, type) => dispatch(setSnackBarMessage(message, type)),
  };
}

const ConnectedCachengoTable = forwardRef((props, ref) => {
  const classes = useStyles();

  const {
    selectedItems,
    setSelectedItems,
    multiSelect = false,
    filterProperty = 'handle',
    getData,
    createNewItemAction,
    refreshItemAction,
    installItemAction,
    removeItemAction,
    moreInfo,
    actions,
    columnHead,
    columns,
    title,
    titleVariant,
    SecondaryHeader=null,
    itemOverride,
    searchOptions
  } = props

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation()
  const history = useHistory()
  let Querypage = (new URLSearchParams(window.location.search)).get("page");
  const [new_page, set_new_page] = useState(true)

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(24);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [defaultSelectedItem, defaultSetSelectedItem] = useState([]);

  const tableSetSelectedItems = setSelectedItems ? setSelectedItems : defaultSetSelectedItem;
  const tableSelectedItems = selectedItems ? selectedItems : defaultSelectedItem;

  useImperativeHandle(
    ref,
    () => ({
      refreshTable: handleRefreshData,
      clearSelectedItems: clearSelectedItems,
    }),
  )

  useEffect(() => {
    if(new_page){
      if (Querypage){
        let newPage = parseInt(Querypage)
        handleGetData(newPage, rowsPerPage)
        setPage(newPage - 1);
      }else{
        history.push(`${location.pathname}?page=${1}`)	
      }
      set_new_page(false)
      return;
    }

    if(Querypage){
      let newPage = parseInt(Querypage)
      handleGetData(newPage, rowsPerPage)
      setPage(newPage - 1);
    }else{
      handleGetData(page + 1, rowsPerPage)
    }

  }, [ Querypage ])

  const clearSelectedItems = ()=>{
    tableSetSelectedItems([])
  }

  const handleGetData = (page, per_page, search) => {
    let organization = props.org.handle;
    let data = { organization, per_page, page }
    if (search){
      data.search_query = search.search_query
      data.search_type = search.search_type
    }
    setIsLoading(true)
    getData(data)
      .then(res => {
        //this handles the error when user deletes the last item on a page,
        //OR when user manually put a page number thats out of range
        if (res.data.length === 0 && res.total > 0) {
          let calcPage = Math.ceil(res.total / per_page)
          setPage(calcPage - 1)
          history.push(`${location.pathname}?page=${calcPage}`)
          return;
        }
        setIsLoading(false)
        setData(res.data)
        setTotalDataCount(res.total)
      }).catch(err => {
        setIsLoading(false)
        props.setSnackBarMessage(props.errMessage, 'error')
      })
  }

  const handleRefreshData = () => {
    handleGetData(page + 1, rowsPerPage)
  }
  
  const onItemSelect = item => {
    const isIn = isInSelectedItem(item, tableSelectedItems, filterProperty)
    if(multiSelect){
      let newItems;
      if (isIn){
        newItems = tableSelectedItems.filter(object => object[filterProperty] !== item[filterProperty]);
      }else{
        newItems = [...tableSelectedItems, item]
      }
      tableSetSelectedItems(newItems)
      return;
    }
    if (isIn) {
      tableSetSelectedItems([])
      return;
    }
    tableSetSelectedItems([item])
  }

  const selectAll = (isSelected, devices) => {
    if(isSelected){
      let ItemstoAdd = [];
      devices.forEach(device => {
        let isIn = isInSelectedItem(device, tableSelectedItems, filterProperty)
        if(!isIn){
          ItemstoAdd.push(device)
        }
      }); 
      tableSetSelectedItems([...tableSelectedItems, ...ItemstoAdd])
  
    }else{
      let itemsToRemove = devices.map(device => device[filterProperty])
      let newItems = tableSelectedItems.filter(object => !itemsToRemove.includes(object[filterProperty]));
      tableSetSelectedItems(newItems)
    }
  }

  const handleChangePage = (event, newPage) => {
    history.push(`${location.pathname}?page=${newPage + 1}`)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowPerPage = parseInt(event.target.value, 10)
    setRowsPerPage(newRowPerPage);
    setPage(0);
    if(Querypage === '1'){
      handleGetData(1, newRowPerPage)
    }else{
      history.push(`${location.pathname}?page=${1}`)
    }
  };

  const handleSearch = (search)=>{
    handleGetData(0, 24, search)
  }

  return (
    <div className={classes.root}>
      <CustomTableHeader 
        createNewItemAction={createNewItemAction}
        refreshItemAction={refreshItemAction}
        installItemAction={installItemAction}
        removeItemAction={removeItemAction}
        actions={actions}
        handleRefreshData={handleRefreshData}
        title={title}
        titleVariant={titleVariant}
        selectedItems={tableSelectedItems}
        clearSelectedItems={clearSelectedItems}
        handleSearch={handleSearch}
        searchOptions={searchOptions}
      />
      {SecondaryHeader} 
      <Divider />
      {isLoading && <LinearProgress color="secondary" />}
      <CustomTableBody 
        data={data}
        moreInfo={moreInfo}
        columnHead={columnHead}
        columns={columns}
        handleItemClick={onItemSelect}
        handleSelectAll={selectAll}
        selectedItems={tableSelectedItems}
        filterProperty={filterProperty}
        itemOverride={itemOverride}
        handleRefreshData={handleRefreshData}
      />
      {data.length < 1 && !isLoading &&
        <div>
          <Typography variant='body2' align='center' style={{padding:'95px 0 115px'}}>
            No records to display
          </Typography>
          <Divider />
        </div>
      }

      <div>
        <TablePagination
          component="div"
          count={totalDataCount}
          page={totalDataCount < 1 ? 0 : page}
          rowsPerPageOptions={[]}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </div>
  )
})

const CachengoTable = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ConnectedCachengoTable);

export default CachengoTable;
