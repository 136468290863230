/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

export const ROOT_URL = process.env.REACT_APP_ROOT_HOST;
export const CACHENGO_API_URL = `https://api.${ROOT_URL}`;
export const CACHENGO_MESSAGING_URL = `wss://messaging.${ROOT_URL}:443/mqtt`;
export const CACHENGO_RENT_URL = `https://rent.${ROOT_URL}`;
export const CACHENGO_PAYMENTS_URL = `https://payments.${ROOT_URL}`;
export const CACHENGO_APPS_URL = `https://apps.${ROOT_URL}`;
export const CACHENGO_REPORTING_URL = `https://reporting.${ROOT_URL}`;
export const STRIPE_PROMISE_KEY = process.env.REACT_APP_STRIPE_PROMISE;

export const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
export const AUTH_PROJECT_RESOURCE_ID = process.env.REACT_APP_AUTH_PROJECT_RESOURCE_ID;
export const AUTH_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
