import { FULL_UPDATE, LOGOUT_USER } from "../constants/action-types";
import { updateOrgList } from "./organization";
import {postJSON, getJSON, checkErrInRes} from '../utils/requests'
import { setSnackBarMessage } from "./interactions";
import jwt_decode from 'jwt-decode';
import { getAppCategories } from "./apps";

var TIMER_ID;
export function checkLogin(payload) {
  return function(dispatch) {
    return dispatch(updateOrgList())
      .then(dispatch(getAppCategories()))
  }
}
