import React, { useState, useEffect }  from 'react';
import { useParams } from "react-router-dom";
import moment from 'moment';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { getMetrics } from '../utils/Cachengo';
import Graph from './Graph';

const Metrics = props => {
  const [metrics, setMetrics] = useState([]);
  const [value, setValue] = useState(0);
  const { id } = useParams();
 
  const dateObject = [
    {range: '1H', fromNow: [1, 'hour']}, 
    {range: '12H', fromNow: [12, 'hour']}, 
    {range: '1D', fromNow: [1, 'days']}, 
    {range: '1M', fromNow: [1, 'months']}, 
    {range: '1Y', fromNow: [1, 'years']}
  ]

  useEffect(() => {
    getMetrics(id, moment().subtract(1, 'hour').unix(), moment().unix(), props.metricType)
      .then(res => res && setMetrics(res.metrics || []))
  }, [id])

  const onClickGetMetrics = (id, fromNow, metric_name) => {
    const end_time = moment().unix();
    const start_time = moment().subtract(fromNow[0], fromNow[1]).unix()
    getMetrics(id, start_time, end_time, metric_name)
      .then(res => setMetrics(res.metrics || []))  
  }


  const tabs = Object.keys(dateObject).map(function(key) {
    let tabStyle = {
      minWidth: 60,
      paddingLeft: 0,
      paddingRight: 0,
      backgroundColor: "white"
    };
    return (
      <Tab key={key} label={dateObject[key].range}  fullWidth={true} style={tabStyle}/>
    )
  });

  const dateButtons = (
    <Tabs
      value={value}
      onChange={(e, value) => {
        onClickGetMetrics(id, dateObject[value].fromNow, props.metricType)
        setValue(value)
      }}
      indicatorColor="secondary"
      textColor="secondary"
    >
      {tabs}
    </Tabs>
  )
  
  return (
    <Graph 
      dateButtons={dateButtons} 
      data={metrics}
      graphTitle={props.graphTitle}
    />
  )
}

export default Metrics;
